type MessageHandler<T> = (message: T) => Promise<any>;

export class MessageQueue<T> {
  private queue: T[] = [];

  private isProcessing: boolean = false;

  private handler: MessageHandler<T>;

  /**
   * 消息队列
   * @param handler 消息处理函数
   */
  constructor(handler: MessageHandler<T>) {
    this.handler = handler;
  }

  /**
   * 添加消息到队列
   * @param message 消息数据
   */
  public enqueue(message: T): void {
    this.queue.push(message);
    this.processQueue();
  }

  /**
   * 处理队列中的消息
   */
  private processQueue(): Promise<void> {
    if (this.isProcessing || this.queue.length === 0) {
      return;
    }
    this.isProcessing = true;

    const message = this.queue.shift() as T;
    this.handler(message).finally(() => {
      this.isProcessing = false;
      this.processQueue();
    });
  }

  /**
   * 获取队列（只读）
   */
  public getQueue(): ReadonlyArray<T> {
    return this.queue;
  }
}
