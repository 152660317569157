/**
 * 将 searchList 转为string
 * @param searchList 待处理的筛选列表
 * @param router 路由
 * @return `string`
 */
export function searchListEncode(searchList: Record<string, unknown>) {
  const list = [];
  for (const key in searchList) {
    if (!Object.prototype.hasOwnProperty.call(searchList, key)) {
      continue;
    }

    if (searchList[key] === undefined) {
      continue;
    }

    list.push(`${key}:${searchList[key]}`);
  }

  return list.join(';');
}
