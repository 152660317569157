<!-- markdown 展示器 -->
<template>
  <div class="marked cherry-markdown" v-html="html" />
</template>

<script setup lang="ts">
import 'cherry-markdown/dist/cherry-markdown.min.css';

import { computed, onBeforeMount } from 'vue';

import { engine } from './engine';

defineOptions({
  name: 'Marked',
});
const props = withDefaults(
  defineProps<{
    /**
     * 需要展示的 markdown 数据
     */
    content: string;
    /**
     * 是否显示光标
     * @default ```false```
     */
    cursor?: boolean;
  }>(),
  { cursor: false },
);
onBeforeMount(() => {
  engine.init();
});

/**
 * ====================
 *       基本逻辑
 * ====================
 */
const html = computed(() => {
  if (!engine.instance.value) {
    return '';
  }

  let finalContent = replaceLastUnclosedMermaid(props.content.replaceAll('\\n', '\n'));

  // 处理光标
  if (props.cursor) {
    const codeBlock = '\n```'; // 避免被转义
    const cursorElm = '<span /> <span class="marked__cursor" />';
    if (!isLastCodeBlockClosed(finalContent)) {
      // 代码块未正确闭合
      finalContent = `${finalContent + codeBlock}\n\n${cursorElm}`;
    } else if (finalContent.trim().endsWith('```')) {
      // 最后的内容是代码块
      finalContent = `${finalContent}\n\n${cursorElm}`;
    } else {
      // 常规
      finalContent = `${finalContent} ${cursorElm}`;
    }
  }

  return engine.instance.value.makeHtml(finalContent);
});
/**
 * 代码块是否正确闭合
 * @param text 文本
 */
function isLastCodeBlockClosed(text: string) {
  // 找出所有的 "```"
  const matches = text.match(/```/g);

  if (!matches) {
    return true; // 如果没有 "```"，则认为没有未闭合的代码块
  }

  // 如果 "```" 的数量是奇数，那么最后一个代码块未闭合
  return matches.length % 2 === 0;
}
/**
 * 替换未闭合的Mermaid内容
 * @param md Markdown文本
 */
function replaceLastUnclosedMermaid(md) {
  // 匹配所有 ```mermaid 开头的代码块
  const startPattern = /```mermaid/g;
  // 匹配所有 ``` 结尾的代码块
  const endPattern = /```/g;

  // 查找所有的 ```mermaid 和 ```，并分别计算它们的出现次数
  const startMatches = [...md.matchAll(startPattern)];
  const endMatches = [...md.replace(startPattern, '').matchAll(endPattern)];

  // 如果没有 mermaid 块或者 mermaid 块已完全闭合，直接返回原文本
  if (startMatches.length === 0 || startMatches.length === endMatches.length) {
    return md;
  }

  // 找到最后一个未闭合的 ```mermaid 代码块的起始位置
  const lastStartIndex = startMatches[startMatches.length - 1].index;
  const lastEndIndex = endMatches[endMatches.length - 1]?.index ?? md.length;

  // 仅当最后一个 mermaid 块没有闭合时，我们才替换它
  // if (lastStartIndex > lastEndIndex) {
  // 替换最后一个未闭合的 mermaid 代码块为指定的替代文本
  md = md.slice(0, lastStartIndex) + md.slice(lastEndIndex);
  // }

  return md;
}
</script>

<style lang="less" scoped>
.marked {
  :deep(p) {
    margin: 0;
    line-height: 22px;
  }

  :deep(p) + p {
    margin-top: 16px;
  }

  :deep(.marked__cursor) {
    display: inline-block;
    width: 3px;
    height: 14px;
    background-color: black;
    animation: cursorBlink 0.8s infinite;
    position: relative;
    top: 2px;
    margin-left: 2px;
  }

  :deep(code) {
    word-break: break-all !important;
  }

  @keyframes cursorBlink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
