import CherryEngine from 'cherry-markdown/dist/cherry-markdown.engine.core.esm';
import { ref } from 'vue';

export class engine {
  /** 引擎实例 */
  static instance = ref<CherryEngine & { makeHtml(md: any): any }>();

  /** 初始化状态 */
  static initializing = false;

  static async init() {
    if (this.instance.value || this.initializing) {
      return;
    }

    this.initializing = true;

    const [{ default: CherryMermaidPlugin }, { default: mermaid }, { default: CherryEngine }] = await Promise.all([
      import('cherry-markdown/dist/addons/cherry-code-block-mermaid-plugin'),
      import('mermaid943'),
      import('cherry-markdown/dist/cherry-markdown.engine.core.esm'),
    ]);
    CherryEngine.usePlugin(CherryMermaidPlugin, {
      mermaid,
    });

    setTimeout(() => {
      this.instance.value = new CherryEngine({}) as CherryEngine & { makeHtml(md: any): any };
      this.initializing = false;
    }, 0);
  }
}
