/**
 * 手机号码
 */
export const usePhoneReg = /^1[3-9]\d{9}$/;

/**
 * 密码
 */
export const passwordReg = /^[a-zA-Z0-9_!@#$%^&*`~()\-+=]{6,16}$/;

/**
 * 验证码
 */
export const useVerifyCodeReg = /^\d{6}$/;

/**
 * 数字
 */
export const useNumberReg = /^\d+$/;

/**
 * 数字带小数点
 */
export const useNumberDotReg = /^-?\d+(\.\d+)?$/;

/**
 * 身份证号码
 */
export const useIdCardReg = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;

/**
 * 数字/价格（千分位）
 */
export const usePriceReg = /^-?\d+(,\d{3})*(\.\d{1,2})?$/;

/**
 * HTML标签
 */
export const useHTMLTagReg = /<[^>]+>/g;

/**
 * 图片文件url
 */
export const imageUrlReg = /^(.*)\.(gif|jpg|jpeg|png)$/;

/**
 * 视频文件url
 */
export const videoUrlReg = /^(.*)\.(mp4|rmvb|wmv|3gp|avi|flv)$/;

/**
 * Base64格式数据
 */
export const base64Reg =
  /^\s*data:(?:[a-z]+\/[a-z0-9-+.]+(?:;[a-z-]+=[a-z0-9-]+)?)?(?:;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*?)\s*$/i;

/**
 * 网址
 */
export const websiteReg =
  /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/;
